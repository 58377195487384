// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-als-js": () => import("./../../../src/pages/als.js" /* webpackChunkName: "component---src-pages-als-js" */),
  "component---src-pages-exercises-js": () => import("./../../../src/pages/exercises.js" /* webpackChunkName: "component---src-pages-exercises-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-health-notice-js": () => import("./../../../src/pages/health-notice.js" /* webpackChunkName: "component---src-pages-health-notice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-sessions-js": () => import("./../../../src/pages/sessions.js" /* webpackChunkName: "component---src-pages-sessions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-activity-activity-template-js": () => import("./../../../src/templates/activity/ActivityTemplate.js" /* webpackChunkName: "component---src-templates-activity-activity-template-js" */)
}

